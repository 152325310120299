// {Experiments.tsx}

import { Box } from '@mui/material';
import React from 'react';
import {
  ExperimentData,
  ManageExperiments as ManageExperimentsContent,
  getExperimentList,
  closeExperiment,
  useAppDispatch,
  closeAssay,
} from 'quil';
import { useNavigate } from 'react-router-dom';
import { useMsal } from '@azure/msal-react';
import { RunPackageDto } from 'quanterix-protos/run_data/v1/db_run_package';
import { createNewExperiment, DataManagementApi, dataMgr } from '../api/DataManagementApi';
// import useStyles from './styles';
// import TEST_ID from './constants';

export interface ExperimentsProps {
  // Props
}

export default function Experiments() {
  // const { classes, cx } = useStyles();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { instance } = useMsal();
  const activeAccount = instance.getActiveAccount();

  const handleOpen = (experimentId: string) => {
    // TODO: Handle unsaved changes
    // As of now they will be lost
    // Closing any open assay ensures the assay editor
    // view in the experiment context is re-rendered
    dispatch(closeAssay());
    dispatch(closeExperiment());
    navigate('editor', { state: { experimentId } });
  };

  const createAndRefresh = async (newExperimentName: string) => {
    await createNewExperiment(newExperimentName);
    await dispatch(getExperimentList());
  };

  const duplicateExperiment = async (
    newExperimentName: string,
    refExperimentId: string,
    asTemplate: boolean,
  ) => {
    const refExperiment = await dataMgr.getExperiment({
      runId: refExperimentId,
    });

    let newExperimentData: Partial<ExperimentData> = {};
    const refExperimentData = refExperiment.experiment?.data;
    if (asTemplate) {
      // Copy setup params only, omit Instrument Data and Analysis Results
      newExperimentData = {
        experiment: RunPackageDto.fromPartial({
          data: {
            ...refExperimentData,
            instrumentDataId: undefined,
            runPackageName: `${refExperimentData?.runPackageName}_${newExperimentName}`,
            createdAccountId: activeAccount?.localAccountId,
            organizationId: activeAccount?.tenantId,
          },
        }),
      };
    } else {
      newExperimentData = {
        experiment: RunPackageDto.fromPartial({
          data: {
            ...refExperimentData,
            runPackageName: `${refExperimentData?.runPackageName}_${newExperimentName}`,
            createdAccountId: activeAccount?.localAccountId,
            organizationId: activeAccount?.tenantId,
          },
        }),
      };
    }

    await DataManagementApi.saveExperiment(newExperimentData);
    await dispatch(getExperimentList());
  };

  const handleCreate = (newExperimentName: string) => {
    // TODO: refreshing the window as a temporary workaround
    // Review state reducers to trigger react DOM render
    createAndRefresh(newExperimentName).then();
  };

  /**
   * This method duplicates the Experiment and assigns it a new id
   * @param id experimentId of the row to duplicate
   */
  const handleDuplicate = (id: string) => {
    // TODO: Customize name
    const date = new Date().toUTCString();
    duplicateExperiment(`${date}`, id, false);
  };

  /**
   * This method duplicates all Setup parameters from the selected
   * experiment, leaving instrument and analysis data unset
   * A new experimentId is assigned to the duplicate
   * @param id experimentId of the row to copy as a template
   */
  const handleNewFromTemplate = (id: string) => {
    // TODO: Customize name
    const date = new Date().toUTCString();
    duplicateExperiment(`${date}`, id, true);
  };

  /**
   * TODO:
   * @param id
   */
  const handleShareTemplate = (id: string) => {
    // TODO:
    alert(`This feature is not implemented (Experiment Id: ${id})`);
  };

  /**
   * TODO:
   * @param id experimentId of the row to copy as a template
   */
  const handleShare = (id: string) => {
    // TODO:
    alert(`This feature is not implemented (Experiment Id: ${id})`);
  };

  return (
    <Box width="100%" height="100%">
      <ManageExperimentsContent
        onOpen={handleOpen}
        onCreate={handleCreate}
        onDuplicateExperiment={handleDuplicate}
        onNewFromTemplate={handleNewFromTemplate}
        onShareExperiment={handleShare}
        onShareTemplate={handleShareTemplate}
      />
    </Box>
  );
}
